
@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css.map");
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

@font-face {
  font-family: SFPro;
  font-weight: 700;
  font-style: bold;
  src: url("./assets/fonts/SF-Pro/SF-Pro-Text-Bold.otf") format("opentype");
}

@font-face {
  font-family: SFPro;
  font-weight: 600;
  font-style: semibold;
  src: url("./assets/fonts/SF-Pro/SF-Pro-Text-Semibold.otf") format("opentype");
}

@font-face {
  font-family: SFPro;
  font-weight: 500;
  font-style: medium;
  src: url("./assets/fonts/SF-Pro/SF-Pro-Text-Medium.otf") format("opentype");
}

@font-face {
  font-family: SFPro;
  font-weight: 400;
  font-style: regular;
  src: url("./assets/fonts/SF-Pro/SF-Pro-Text-Regular.otf") format("opentype");
}

@font-face {
  font-family: SFPro;
  font-weight: 300;
  font-style: light;
  src: url("./assets/fonts/SF-Pro/SF-Pro-Text-Light.otf") format("opentype");
}

@font-face {
  font-family: SFMono;
  font-weight: 700;
  font-style: bold;
  src: url("./assets/fonts/SF-Mono/SF-Mono-Bold.otf") format("opentype");
}

@font-face {
  font-family: SFMono;
  font-weight: 600;
  font-style: semibold;
  src: url("./assets/fonts/SF-Mono/SF-Mono-Semibold.otf") format("opentype");
}

@font-face {
  font-family: SFMono;
  font-weight: 500;
  font-style: medium;
  src: url("./assets/fonts/SF-Mono/SF-Mono-Medium.otf") format("opentype");
}

@font-face {
  font-family: SFMono;
  font-weight: 400;
  font-style: regular;
  src: url("./assets/fonts/SF-Mono/SF-Mono-Regular.otf") format("opentype");
}

@font-face {
  font-family: SFMono;
  font-weight: 300;
  font-style: light;
  src: url("./assets/fonts/SF-Mono/SF-Mono-Light.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-family: "SF Pro Text", sans-serif;
}

form input {
  margin-bottom: 0.75em;
}

form label {
  margin-bottom: 0.25em;
  display: inline-block;
}
